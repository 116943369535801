import jquery from "jquery";
export default (window.$ = window.jQuery = jquery);

$(document).ready(function() {

    $(document).keydown(function(event) {
        if (event.keyCode === 27) { 
            $(".modal.is-active").removeClass("is-active");
            $(".dropdown.is-active").removeClass("is-active");
        }
    });

});

window.getSearchTerm = function() {
    var searchTerm = (new URL(location.href)).searchParams.get("st");
    if (!searchTerm) searchTerm = "";
    return searchTerm;
};

window.addSearchTermToUrl = function(url, param) {
    var searchTerm = $("#dt-search-0").val();
    if (searchTerm) {
        var params = new URLSearchParams(url.search);
        params.append(param, searchTerm);
        url.search = params;
    }
    return url;
};
