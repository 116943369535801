import "./main.js";

$(document).ready(function() {

    var dropDowns = $(".dropdown:not(.is-hoverable)").toArray();

    if (dropDowns.length > 0) {
        $.each(dropDowns, function() {
            $(this).on("click", function(event) {
                event.stopPropagation();
                $(this).toggleClass("is-active");
            });
        });
        $(document).on("click", function() {
            $(".dropdown.is-active").each(function() {
                $(this).removeClass("is-active");
            });
        });
    }

    $('#orgSelector').on('change', function() {
        var orgId = $('#orgSelector').val();
        $.ajax({
            type: 'GET',
            url: '/org/switch/' + orgId,
            success: function (response) {
                location.reload(true);
            }
        });
    });

});
